<template>
  <button>
    <slot />
  </button>
</template>

<style lang="scss" scoped>
button {
  width: 250px;
  padding: 24px 24px 25px;
  border: none;
  border-radius: 12px;
  background: #ffe68d;
  font-family: "Mayenne Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  transition: background 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #ead073;
  }

  &:active {
    background: #dbc062;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
</style>
